import { DentrinoApiResult } from '@/components/DentrinoVirtualTryOn/DentrinoVirtualTryOn.helpers';
import { getLogger } from '@/logging/logger';
const logger = getLogger('design-system');

export default async function getResultsFromDentrinoAI(authToken: string, formData: FormData, url: string) {
	try {
		const res = await fetch(url, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${authToken}`,
			},
			body: formData,
		});
		const data = (await res.json()) as DentrinoApiResult;
		return data;
	} catch (err) {
		if (err instanceof Error) {
			logger.error(`DENTRINO_ERROR_API_FAILURE: ${err.message}`);
		}
	}
}
