import { FC, MouseEventHandler, useState } from 'react';

import dynamic from 'next/dynamic';

import { AnalyticsContext, Button as IButton, Maybe } from '@/types/generated';
import {
	resolveContentfulButtonSize,
	resolveContentfulButtonVariant,
	resolveContentfulResponsiveButton,
	ButtonIconSizes,
} from '@/utils';
import { ContentfulButtonSize, ContentfulButtonVariant } from '@/types';
import TAGSvgIcon, { IconName } from '@/components/TAGSvgIcon';
import TAGButton, { type ITAGButton } from '@/components/TAGButton';
import { useAppContext } from '@/context';

import Quiz from '../Quiz';
import { TFormData, quizGA4DataLayerPush } from '../Quiz/Quiz.helpers';
import { dentrinoGA4DataLayerPush } from '../DentrinoVirtualTryOn/DentrinoVirtualTryOn.helpers';

import { buttonStyles } from './ContentfulButton.styles';

const DentrinoVirtualTryOn = dynamic(() => import('../DentrinoVirtualTryOn/DentrinoVirtualTryOn'));

interface IContentfulButton extends IButton {
	isSmallScreen?: boolean;
	dataTestId?: string;
	defaultVariant?: ITAGButton['variant'];
	onClick?: MouseEventHandler<HTMLButtonElement>;
	fullWidth?: boolean;
	analyticsContext?: Maybe<AnalyticsContext>;
}

const openLinkIn = (link: string, typeOfOpening: 'same_tab' | 'new_tab' | 'new_window') => {
	if (typeOfOpening === 'new_tab') {
		window.open(link, '_blank');
	} else if (typeOfOpening === 'new_window') {
		window.open(link, '_blank', 'width=600,height=400');
	} else {
		window.location.href = link;
	}
};

export const buttonIconSize = (size?: Maybe<ButtonIconSizes>): number => {
	const sizes = {
		XSmall: 12,
		Small: 16,
		Medium: 25,
		Large: 32,
		XLarge: 38,
	} as { [key: string]: number };
	return sizes[size || 'Medium'];
};

const ContentfulButton: FC<IContentfulButton> = ({
	defaultVariant,
	dataTestId = 'tag_button',
	isSmallScreen = false,
	onClick = () => undefined,
	fullWidth = false,
	quizDetails,
	...buttonData
}) => {
	const [state, setState] = useState<{
		showQuiz: boolean;
		showDentrinoVirtualTryOn: boolean;
	}>({
		showQuiz: false,
		showDentrinoVirtualTryOn: false,
	});
	const { config } = useAppContext();

	if (!buttonData) return null;
	const button = resolveContentfulResponsiveButton(buttonData, isSmallScreen);
	const typeOfOpening = button.openInNewWindow ? 'new_window' : button.openInNewTab ? 'new_tab' : 'same_tab';
	const handleRenderQuiz = (quiz: string) => {
		switch (quiz) {
			case 'ToothReplacement':
				setState({
					...state,
					showQuiz: true,
				});
				quizGA4DataLayerPush(config.name, 'start', 'N/A', quizDetails?.quizFormData as TFormData);
				break;
			case 'DentrinoVirtualTryOn':
				setState({
					...state,
					showDentrinoVirtualTryOn: true,
				});
				dentrinoGA4DataLayerPush(config.name, 'start');
				break;
			default:
				return null;
		}
	};

	const handleCloseQuiz = () => {
		setState({
			...state,
			showQuiz: false,
			showDentrinoVirtualTryOn: false,
		});
	};

	return (
		<>
			{state.showQuiz ? (
				<Quiz
					openQuiz={state.showQuiz}
					handleCloseQuiz={handleCloseQuiz}
					quizFormData={quizDetails?.quizFormData as JSON}
					submitEndpointUrl={quizDetails?.submitEndpointUrl || ''}
					showInDialogOrDrawer={true}
				/>
			) : null}
			{state.showDentrinoVirtualTryOn ? (
				<DentrinoVirtualTryOn
					openVirtualTry={state.showDentrinoVirtualTryOn}
					handleCloseVirtualTry={handleCloseQuiz}
				/>
			) : null}
			<TAGButton
				href={button.externalUrl || quizDetails?.quizType ? undefined : button.link}
				size={resolveContentfulButtonSize(button.size as ContentfulButtonSize)}
				variant={resolveContentfulButtonVariant(button.variant as ContentfulButtonVariant, defaultVariant)}
				startIcon={
					button?.iconPosition === 'start' && button?.icon ? (
						<TAGSvgIcon
							icon={button.icon as IconName}
							size={buttonIconSize(button.iconSize || 'Medium')}
							dataTestId={`${dataTestId}_start_icon_${button.id}`}
						/>
					) : null
				}
				endIcon={
					button?.iconPosition === 'end' && button?.icon ? (
						<TAGSvgIcon
							icon={button.icon as IconName}
							size={buttonIconSize(button.iconSize || 'Medium')}
							dataTestId={`${dataTestId}_end_icon_${button.id}`}
						/>
					) : null
				}
				analyticsContext={buttonData?.analyticsContext}
				onClick={(e) => {
					if (quizDetails?.quizType) {
						handleRenderQuiz(quizDetails.quizType);
						return;
					}
					if (button.externalUrl) {
						openLinkIn(button.externalUrl, typeOfOpening);
					}
					onClick(e);
				}}
				sx={{ ...buttonStyles, px: button.icon ? '0.875rem' : '1.5rem', width: fullWidth ? '100%' : 'auto' }}
				dataTestId={`${dataTestId}_${button.id}`}
			>
				{button.label}
			</TAGButton>
		</>
	);
};

export default ContentfulButton;
